import React from "react"
import IconShoppingCart from "../../icons/IconShoppingCart"
import styled from "styled-components"
import { navigate } from "gatsby"

const Button = styled.button`
  padding: 1px 0px 5px 0px;
  cursor: pointer;
  border-radius: 100px;
  display: flex;
  height: 30px;
  align-items: center;
  line-height: 0;
`
const NumberBadge = styled.div`
  transition: opacity 0.1s, transform 0.1s;
  position: absolute;
  left: 100%;
  letter-spacing: 0.05em;
  top: 0;
  font-size: 12px;
  color: #fff;
  min-width: 2ch;
  padding: 4px 7px 3px;
  border-radius: 100px;
  font-weight: bold;
  background: var(--accent);
  line-height: 1;
  &.zero {
    background: #fff;
    color: #777;
    border: 1px solid rgba(0, 0, 0, 0.5);
  }
`

const Badge = ({ num, loading }) => {
  const y = loading ? "5px" : "0px"
  const opacity = loading ? 0 : 1
  return (
    <NumberBadge
      className={num === 0 ? "zero" : ""}
      style={{
        opacity,
        transform:
          num > 9
            ? `translateX(-65%) translateY(${y}) translateZ(0)`
            : `translateX(-90%) translateY(${y}) translateZ(0)`,
      }}
    >
      {num > 9 ? `9+` : num}
    </NumberBadge>
  )
}

const NavBarCart = ({ num, loading }) => {
  const onShow = () => navigate(`?&cart=true#myshop`)
  return (
    <>
      <Button onClick={onShow}>
        {/* Sizing is based on viewbox of shopping carts */}
        <div
          style={{
            width: 33.2,
            height: 30,
            position: "relative",
          }}
        >
          <IconShoppingCart
            style={{
              left: 0,
              bottom: 0,
              position: "absolute",
              width: "auto",
              height: 24,
              fill: "transparent",
              stroke: "#333",
            }}
          />
          <Badge num={num} loading={loading} />
        </div>
      </Button>
    </>
  )
}

export default NavBarCart

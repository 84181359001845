import React, { useState, useRef } from "react"
import TenantLogo from "../../core/TenantLogo"
import useTenantSignedInContext from "../../hooks/useTenantSignedInContext"
import NavBarLoginPlaceholder from "./NavBarLoginPlaceholder"
import NavBarLoginDropdown from "./NavBarLoginDropdown"
import LogoArea from "./NavBarLoginLogoArea"

const NavBarLogin = ({ style = {}, trackingPrefix, props }) => {
  const { tenantSignedIn, loading } = useTenantSignedInContext()
  const target = useRef(null)
  const [show, setShow] = useState(false)

  // Just a placeholder to prevent cart blinking
  if (loading)
    return (
      <LogoArea style={{ visibility: "hidden", ...style }} {...props}>
        <div />
      </LogoArea>
    )

  const tenantInfo = tenantSignedIn || {}
  return (
    <>
      <LogoArea
        as={"button"}
        style={{
          visibility: "visible",
          cursor: "pointer",
          ...(tenantInfo?.tenantName ? {} : { boxShadow: "none" }),
          ...style,
        }}
        {...props}
        ref={target}
        onPointerDown={() => setShow((v) => !v)}
        // Not sure why enter/leave not firing but over/out will do
        onMouseLeave={() => {
          setShow(false)
        }}
        onMouseEnter={() => {
          setShow(true)
        }}
      >
        <>
          {tenantInfo && tenantInfo.tenantName ? (
            <TenantLogo tenantInfo={tenantInfo} />
          ) : (
            <NavBarLoginPlaceholder />
          )}
        </>
      </LogoArea>
      <NavBarLoginDropdown
        trackingPrefix={trackingPrefix}
        setShow={setShow}
        show={show}
        target={target}
        tenantInfo={tenantInfo}
      />
    </>
  )
}

export default NavBarLogin

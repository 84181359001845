import React, { useEffect, useRef, createContext, useContext } from "react"
import queryString from "query-string"

export const WidgetModeContext = createContext(false)
export const WidgetModeProvider = ({ location, children }) => {
  const { widgetMode } = location ? queryString.parse(location.search) : {}
  const ref = useRef(!!widgetMode)
  useEffect(() => {
    if (widgetMode) ref.current = true // only care when widgetMode is true
  }, [widgetMode])
  return (
    <WidgetModeContext.Provider value={ref.current}>
      {children}
    </WidgetModeContext.Provider>
  )
}

const useWidgetModeContext = () => {
  const context = useContext(WidgetModeContext)
  if (context === undefined) {
    // Let's yell at ourselves to make sure we use our Provider wrapper
    throw new Error(
      "Oooops, I'm guessing your forgot to use the Provider for this context"
    )
  }
  return context
}

export default useWidgetModeContext

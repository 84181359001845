import styled from "styled-components"

const contentMaxWidth = 875
const contentPadding = "0px var(--content-pad)"
export const Content = styled.div`
  visibility: ${({ hidden }) => (hidden ? "hidden" : "visible")};
  max-width: ${contentMaxWidth}px;
  margin: 0 auto;
  padding: ${contentPadding};
`

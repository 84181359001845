import React from "react"
import styled from "styled-components"
import TenantLogo from "../../core/TenantLogo"
import LogoArea from "./NavBarLoginLogoArea"
import useTenantSignedInContext from "../../hooks/useTenantSignedInContext"
import Popover from "../../core/Popover"
import { segmentAction } from "../../hooks/useSegmentTrack"

const LoggedInContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #eee;
  line-height: 1.2;
`

const Links = styled.div`
  display: block;
  padding: 0.3rem 10px;
  cursor: pointer;
  transition: 0.05s;
  margin-left: -10px; /* Some extra padding */
  margin-right: -10px; /* Some extra padding */
  /* outline: 2px solid red; */
  :link,
  :visited {
    color: inherit;
  }
  :hover {
    color: ${({ theme }) => theme.colors.accent};
  }
`

const NavBarLoginDropdown = ({
  show,
  target,
  tenantInfo,
  setShow,
  trackingPrefix, // Homepage vs Sparkles Page
  isAccountMobileExpanded, // used to style mobile account dropdown
}) => {
  const { onLogout, homepageUrl, adminUrl, contactUrl } =
    useTenantSignedInContext()
  return (
    <Popover target={(target || {}).current} show={show} setShow={setShow}>
      <div
        style={{
          padding: 0,
          maxWidth: 200,
          minWidth: 150,
          fontSize: "0.9rem",
        }}
      >
        <LoggedInContainer>
          <LogoArea style={{ width: 33, height: 33, marginRight: 10 }}>
            <TenantLogo tenantInfo={tenantInfo} />
          </LogoArea>
          {/* This isn't necessarily true... need to get our logged in tenant info */}
          <div style={{ flex: 1 }}>
            <strong>{tenantInfo.tenantName}</strong>
          </div>
        </LoggedInContainer>
        <div style={{ padding: ".5rem 1rem" }}>
          <Links
            as={"a"}
            href={homepageUrl}
            onClick={() => {
              segmentAction.track(
                `${trackingPrefix} Login Panel To Own Site Click`
              )
            }}
          >
            My Site
          </Links>
          <Links
            as={"a"}
            rel="noopener noreferrer"
            target="_blank"
            onClick={() => {
              segmentAction.track(
                `${trackingPrefix} Login Panel Settings Click`
              )
            }}
            href={adminUrl}
          >
            Settings
          </Links>
        </div>
        <div
          style={{
            paddingInline: "1rem",
            borderTop: "1px solid #eee",
          }}
        >
          <Links as={"a"} href={contactUrl} target={"_blank"}>
            Contact Us
          </Links>
        </div>
        <div
          style={{
            padding: ".5rem 1rem .7rem",
            borderTop: "1px solid #eee",
            fontWeight: "bold",
          }}
        >
          <Links
            onClick={() => {
              segmentAction.track(`${trackingPrefix} Login Panel Logout`)
              onLogout()
            }}
          >
            Log out
          </Links>
        </div>
      </div>
    </Popover>
  )
}

export default NavBarLoginDropdown

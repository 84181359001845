import React from "react"
import NavBar from "./NavBar"
import Footer from "./Footer"
import GlobalStyles from "./GlobalStyles"
// import BackToTop from "./BackToTop"
import styled from "styled-components"
import { ThemeProvider } from "styled-components"
import "./layout.css"
import useWidgetModeContext from "../../hooks/useWidgetModeContext"

export const staticColors = {
  error: "#D82C26",
}
export const sparkleAccent = "#e43c4a"

const makeTheme = ({
  accent = sparkleAccent, // Normal Sparkle Theme
}) => {
  return {
    colors: {
      ...staticColors,
      accent,
      sparkleAccent,
    },
    // TODO: Have big changes set here like colors + border-radiuses... maybe in header vs body fonts
    // OR... PUT INTO CSS VARS
  }
}

const Container = styled.div`
  display: grid;
  align-content: space-between;
  min-height: 100vh;
  background: #f7f7f7;
`

const Layout = ({ children, accent, showCart = false }) => {
  const widgetMode = useWidgetModeContext()
  const showNav = !widgetMode
  const showFooter = !widgetMode
  return (
    <ThemeProvider theme={makeTheme({ accent })}>
      <GlobalStyles showFooter={showFooter} />
      <Container>
        <div>
          {showNav && <NavBar showCart={showCart} />}
          {widgetMode ? (
            <div>{children}</div>
          ) : (
            <main style={{ background: "#f7f7f7" }}>{children}</main>
          )}
        </div>
        {showFooter && <Footer />}
      </Container>
      {/* <BackToTop /> */}
    </ThemeProvider>
  )
}

export default Layout

import React from "react"

const IconShoppingCart = ({ ...props }) => {
  return (
    <svg viewBox="0 0 83 75" {...props}>
      <path
        transform={"rotate(27) translate(5 -22)"}
        strokeWidth={0}
        d="M49.45.36l-22.33,12a3,3,0,0,0-1.23,4.06l12,22.33a3,3,0,0,0,4.06,1.23L64.24,28a3,3,0,0,0,1.23-4.06l-12-22.34A3,3,0,0,0,49.45.36Z"
      />
      <g
        fill={"none"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
        strokeWidth={8}
      >
        <polyline points="4.06 16.9 14.51 17.81 22.23 50.64 70.83 50.64 78.64 22.83" />
        <circle cx="24.77" cy="66.53" r="3.66" />
        <circle cx="67.65" cy="66.53" r="3.66" />
      </g>
    </svg>
  )
}

export default IconShoppingCart

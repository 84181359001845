import chroma from "chroma-js"

// Base styling to be shared with buttons
export const containerStyling = `
  font-weight: bold;
  background: #fff;
  padding: 0.8em 1.75em;
  border: 1px solid #efefef;
  color: #333;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 300px; /* absurdly large */
  transition: 0.2s;
  :hover, :focus, :visited {
    color: #333; /* no hover styling... trying to override bootstrap defaults */
  }
  /* Hover styling */
  &.accent {
    :focus,
    :hover {
      color: ${({ theme }) => theme.colors.accent};
      border: 1px solid ${({ theme }) => theme.colors.accent};
      box-shadow: 0 0 0 5px
        ${({ theme }) => chroma(theme.colors.accent).alpha(0.25)};
    }
  }
  &.ring {
    :focus,
    :hover {
      border: 1px solid #efefef;
      box-shadow: 0 0 0 5px
        ${({ theme }) => chroma(theme.colors.accent).alpha(0.25)};
    }
  }
  &.black {
    :focus,
    :hover {
      color: #000;
      border: 1px solid #000;
      box-shadow: 0 0 0 5px rgb(52 58 64 / 50%);
    }
  }
`

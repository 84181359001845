import React from "react"
import isEmpty from "lodash/isEmpty"
import useTenantSignedInContext from "../../hooks/useTenantSignedInContext"
import { ButtonGrayHover } from "../../core/Buttons"
import { segmentAction } from "../../hooks/useSegmentTrack"

const NavBarQuickLink = ({ style }) => {
  const { tenantSignedIn, onOwnSite, homepageUrl, adminUrl } =
    useTenantSignedInContext()
  if (isEmpty(tenantSignedIn)) return null
  return (
    <ButtonGrayHover
      as={"a"}
      onClick={() => {
        segmentAction.track(
          onOwnSite
            ? "Sparkles Nav Settings Click"
            : "Sparkles Nav To Own Site Click",
          onOwnSite ? { ownPageUrl: homepageUrl } : {}
        )
      }}
      href={onOwnSite ? adminUrl : homepageUrl}
      style={{
        ...style,
      }}
    >
      {onOwnSite ? "Edit site + settings" : "To my site →"}
    </ButtonGrayHover>
  )
}

export default NavBarQuickLink

import React from "react"
import StaticImage from "gatsby-plugin-image"
import { Content } from "./Content"
import useTenantSignedInContext from "../../hooks/useTenantSignedInContext"
import useTenantInfoContext from "../../hooks/useTenantInfoContext"
import { segmentAction } from "../../hooks/useSegmentTrack"
import styled from "styled-components"

import IconLogo from "../../icons/IconLogo"

const Footer = () => {
  const { tenantSignedIn, onLogIn } = useTenantSignedInContext()
  const tenantInfo = useTenantInfoContext()
  const isSignedIn = tenantSignedIn?.tenantName
  return (
    // Need div wrapper due to grid layout this is placed in
    <Bg>
      <Content>
        {/* Footer */}
        {/* TODO: Maybe a logout? */}
        <Container>
          {!isSignedIn ? (
            <div style={{ order: 1 }}>
              <Link
                as={"button"}
                onClick={() => {
                  segmentAction.track("Sparkles Footer Login")
                  onLogIn()
                }}
                style={{
                  textDecoration: "underline",
                  textUnderlineOffset: 4,
                  display: "block",
                  marginBottom: 10,
                  width: "100%",
                  textAlign: "right",
                }}
              >
                Admin Log In
              </Link>
              <div style={{ textAlign: "right", width: "100%" }}>
                {tenantInfo?.tenantName}
              </div>
            </div>
          ) : (
            <div style={{ order: 1 }}>
              <div
                style={{ marginBottom: 10, textAlign: "right", width: "100%" }}
              >
                Welcome!
              </div>
              <div style={{ textAlign: "right", width: "100%" }}>
                {tenantInfo?.tenantName}
              </div>
            </div>
          )}
          <div style={{ order: 0 }}>
            <span style={{ display: "block" }}>
              <Link
                href="https://wesparkle.co"
                target="_blank"
                onClick={() =>
                  segmentAction.track("Sparkles Footer Logo Click")
                }
              >
                {/*
                <IconLogo
                  onlyMark
                  markProps={{ fill: "var(--wesparkle-red)" }}
                  style={{
                    width: 35,
                    height: "auto",
                    transform: "translateX(-2%)",
                  }}
                />
              */}
                <img
                  src={"/powered-by-WS.svg"}
                  style={{
                    height: 45,
                  }}
                  alt="Powered by WeSparkle"
                />
              </Link>
            </span>
            <span
              style={{
                marginTop: "1rem",
                display: "block",
                overflow: "visible",
              }}
            ></span>
          </div>
          {/* <div>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://helpcenter.wesparkle.ai/category/Privacy-&-Terms"
            >
              Privacy &amp; Terms
            </Link>
            <div style={{ marginTop: ".5em" }}>©2021 We Sparkle Co.</div>
          </div> */}
        </Container>
      </Content>
    </Bg>
  )
}

export default Footer

/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const Container = styled.footer`
  padding: calc(var(--content-pad) * 2) var(--content-pad);
  font-size: 0.85rem;
  display: flex;
  justify-content: space-between;
`

const Link = styled.a`
  color: currentColor;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  &:hover {
    color: ${({ theme }) => theme.colors.accentContrast};
    text-decoration: underline;
  }
`
const Bg = styled.div`
  position: relative;
  background-color: #f7f7f7;
  border-top: 1px solid #ddd;
`
